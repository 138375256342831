const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    //component: "UnderConstruction",
    component: "Home",
  },
  {
    path: ["*/reset-password"],
    exact: true,
    component: "ResetPassword",
  },
  {
    path: ["*/confirm-email"],
    exact: true,
    component: "ConfirmEmail",
  },
  {
    path: ["*/accept-invite"],
    exact: true,
    component: "AcceptInvite",
  },
  {
    path: ["*/app"],
    exact: true,
    component: "DownloadApp",
  },
  {
    path: ["*/privacy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["*"],
    exact: true,
    component: "DownloadApp",
  },
];

export default routes;
