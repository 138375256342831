import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "./styled-components";
import { themes } from "./theme";

export const CustomThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [customTheme, setCustomTheme] = useState(themes.curtisTheme);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname.includes("/deepteam")) {
      setCustomTheme(themes.deepteamTheme);
    }
    if (location?.pathname.includes("/curtis-test")) {
      setCustomTheme(themes.curtisTestTheme);
    }
    if (location?.pathname.includes("/yeltsin")) {
      setCustomTheme(themes.yeltsinTheme);
    }
    if (location?.pathname.includes("/serotonina")) {
      setCustomTheme(themes.serotoninaTheme);
    }
    // TODO: more themes here
  }, [location.pathname]);

  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};
