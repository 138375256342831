import { CustomTheme } from "../theme";

export const deepteamTheme: CustomTheme = {
  colors: {
    primary: "#000",
    secondary: "#404041",
    background: "rgb(241, 242, 243)",
    text: "#000",
    borders: "#edf3f5",
    title: "#000",
    navLink: "#15418e",
    labels: "#404041",
    error: "#ea4a40",
  },
  links: {
    home: "/deepteam/app",
    logo: "/logos/deepteam-black.svg",
    supportEmail: "info@deepteam.es",
    googlePlay:
      "https://play.google.com/store/apps/details?id=com.curtisapps.deepteam",
    appStore: "https://apps.apple.com/es/app/deepteam/id6505021256",
  },
  title: "Deepteam",
};
