import { CustomTheme } from "../theme";

export const yeltsinTheme: CustomTheme = {
  colors: {
    primary: "#A87205",
    secondary: "#404041",
    background: "#fff",
    text: "#000",
    borders: "#edf3f5",
    title: "#000",
    navLink: "#15418e",
    labels: "#404041",
    error: "#ea4a40",
  },
  links: {
    home: "/yeltsin-barber/app",
    logo: "/logos/yeltsin_logo.jpg",
    supportEmail: "info@curtisapps.com",
    // TODO: add real links here
    googlePlay: "",
    appStore: "",
  },
  title: "Yeltsin Barber",
};
