import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "antd/dist/antd.css";
import { I18nextProvider } from "react-i18next";

import Router from "./router";
import i18n from "./translation";

import { CustomThemeProvider } from "./styles/CustomThemeProvider";

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <CustomThemeProvider>
        <Router />
      </CustomThemeProvider>
    </I18nextProvider>
  </BrowserRouter>
);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
