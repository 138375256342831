import { CustomTheme } from "../theme";

export const curtisTestTheme: CustomTheme = {
  colors: {
    primary: "#07255A",
    secondary: "#404041",
    background: "rgb(241, 242, 243)",
    text: "#000",
    borders: "#edf3f5",
    title: "#000",
    navLink: "#15418e",
    labels: "#404041",
    error: "#ea4a40",
  },
  links: {
    home: "/curtis-test/app",
    logo: "/logos/deepteam-black.svg",
    supportEmail: "info@curtisapps.es",
    // TODO: add real links here
    googlePlay: "",
    appStore: "",
  },
  title: "Curtis Test",
};
