import { curtisTestTheme } from "./themes/curtis-test";
import { deepteamTheme } from "./themes/deepteam";
import { serotoninaTheme } from "./themes/serotonina";
import { yeltsinTheme } from "./themes/yeltsin";

export interface CustomTheme {
  colors: {
    primary: string;
    secondary: string;
    background: string;
    text: string;
    borders: string;
    title: string;
    navLink: string;
    labels: string;
    error: string;
  };
  links: {
    home: string;
    logo: string;
    supportEmail: string;
    googlePlay: string;
    appStore: string;
  };
  title: string;
}

const curtisTheme: CustomTheme = {
  colors: {
    primary: "#070c06", // logo border
    secondary: "#484948",
    // secondary: "#dee0d5", // logo inner
    background: "#fff",
    text: "#333333",
    borders: "#edf3f5",
    title: "#333333",
    navLink: "#004869",
    labels: "#333333",
    error: "#ea4a40",
  },
  links: {
    home: "/home",
    logo: "/logos/curtis-logo-final.png",
    supportEmail: "info@curtisapps.com",
    googlePlay: "",
    appStore: "",
  },
  title: "Curtis",
};

export const themes = {
  curtisTheme,
  deepteamTheme,
  curtisTestTheme,
  yeltsinTheme,
  serotoninaTheme,
};
